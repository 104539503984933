var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Dialog",
    {
      staticClass: "timeslot-viewing",
      attrs: { name: _vm.name, width: "475px" },
      on: { close: _vm.handleClearFields },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function () {
            return [_c("div", [_vm._v("Просмотр тайм слота")])]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "timeslot-viewing__main" },
        [
          _c("iq-title", { attrs: { "class-name": "mobileHeader" } }, [
            _vm._v("Тайм слот: " + _vm._s(_vm.getDate)),
          ]),
          _c(
            "el-form",
            {
              ref: "formAddTimeslot",
              staticClass: "dialog-edit-timeslot",
              attrs: {
                model: _vm.formAddTimeslot,
                rules: _vm.rules,
                "label-width": "175px",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c("iq-input-form", {
                staticClass: "m-b-20",
                attrs: {
                  label: "Номер Авто",
                  prop: "num_auto",
                  "label-width": "175px",
                  "input-width": "265px",
                  rules: _vm.rules.num_auto,
                },
                model: {
                  value: _vm.formAddTimeslot.num_auto,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "num_auto", $$v)
                  },
                  expression: "formAddTimeslot.num_auto",
                },
              }),
              _c("iq-select-form", {
                attrs: {
                  label: "Тип авто",
                  prop: "type",
                  rules: _vm.rules.type,
                  options: _vm.autoType,
                  disabled: "",
                  "key-label": "title",
                  "key-value": "val",
                  "label-width": "175px",
                  "input-width": "265px",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "type", $$v)
                  },
                  expression: "formAddTimeslot.type",
                },
              }),
              _c("iq-select-form", {
                attrs: {
                  label: "Культура",
                  prop: "culture_id",
                  rules: _vm.rules.culture_id,
                  options: _vm.cultureList,
                  "key-label": "name",
                  "key-value": "id",
                  "label-width": "175px",
                  "input-width": "265px",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.culture_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "culture_id", $$v)
                  },
                  expression: "formAddTimeslot.culture_id",
                },
              }),
              _c("iq-select-form", {
                attrs: {
                  label: "Экспортер",
                  disabled: "",
                  rules: _vm.rules.exporter_id,
                  options: _vm.exportersList,
                  "key-label": "name",
                  "key-value": "id",
                  "label-width": "175px",
                  "input-width": "265px",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.exporter_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "exporter_id", $$v)
                  },
                  expression: "formAddTimeslot.exporter_id",
                },
              }),
              _c("iq-input-form", {
                staticClass: "m-b-20",
                attrs: {
                  label: "Статус",
                  "label-width": "175px",
                  "input-width": "265px",
                  disabled: "",
                },
                model: {
                  value: _vm.formAddTimeslot.truck_status,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "truck_status", $$v)
                  },
                  expression: "formAddTimeslot.truck_status",
                },
              }),
              _c("iq-input-form", {
                staticClass: "m-b-20",
                attrs: {
                  label: "Дата получения",
                  "label-width": "175px",
                  "input-width": "265px",
                  disabled: "",
                },
                model: {
                  value: _vm.formAddTimeslot.date_cre,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "date_cre", $$v)
                  },
                  expression: "formAddTimeslot.date_cre",
                },
              }),
              _c("iq-input-form", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "+7 ### ###-##-##",
                    expression: "'+7 ### ###-##-##'",
                  },
                ],
                staticClass: "m-b-20",
                attrs: {
                  label: "Телефон водителя",
                  prop: "phone",
                  "label-width": "175px",
                  "input-width": "265px",
                  rules: _vm.rules.phone,
                },
                model: {
                  value: _vm.formAddTimeslot.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "phone", $$v)
                  },
                  expression: "formAddTimeslot.phone",
                },
              }),
              _c("iq-select-form", {
                attrs: {
                  label: "Проезд",
                  prop: "detach",
                  rules: _vm.rules.detach,
                  options: [
                    { id: 0, name: "Разрешен" },
                    { id: 1, name: "Отказан" },
                  ],
                  "key-label": "name",
                  "key-value": "id",
                  "label-width": "175px",
                  "input-width": "265px",
                  "is-super-important-label-width": true,
                },
                model: {
                  value: _vm.formAddTimeslot.detach,
                  callback: function ($$v) {
                    _vm.$set(_vm.formAddTimeslot, "detach", $$v)
                  },
                  expression: "formAddTimeslot.detach",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "timeslot-viewing__timeslot-wrap-btn" },
            [
              _c(
                "iq-button",
                {
                  attrs: {
                    size: "small",
                    disabled: _vm.isLoadingPostTimeslots,
                  },
                  on: { onClick: _vm.handleConfirmTimeslot },
                },
                [_vm._v(" Подтвердить приезд авто ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }